import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Card, Typography } from "@mui/material";
import {
  DirectionsCar,
  BarChart,
  Apartment,
  DateRange,
  Key,
  Hotel,
  Lock,
  WifiPassword,
} from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSpring, animated, easings } from "react-spring";
import getWindowDimensions from "../../custom/windowDimensions";
import { themeColor } from "../../custom/constants";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function HomePage({ name }) {
  const [anim, setAnim] = useState(false);
  const [anim2, setAnim2] = useState(false);
  const [anim3, setAnim3] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  let navigate = useNavigate();

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    transform: anim ? "translateY(0%)" : "translateY(-50%)",
    config: { duration: 600, easing: easings.easeInOutQuint },
  });

  const props2 = useSpring({
    scale: anim2 ? 1 : 0,
    opacity: anim2 ? 1 : 0,
    config: { duration: 600, easing: easings.easeInOutQuint },
  });

  const props3 = useSpring({
    scale: anim3 ? 1 : 0,
    opacity: anim3 ? 1 : 0,
    config: { duration: 600, easing: easings.easeInOutQuint },
  });

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    setAnim(true);
    setTimeout(() => {
      setAnim2(true);
      setTimeout(() => {
        setAnim3(true);
      }, 300);
    }, 300);
    if (localStorage.getItem("Chainrents_id")) {
      navigate({
        pathname: "/dashboard",
        search: `?id=${localStorage.getItem("Chainrents_id")}`,
      });
      return;
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div style={{ height: "100%", paddingBottom: "10%" }}>
        <animated.div
          className="stack-container"
          style={{
            // background: "red",
            opacity: props.opacity,
            transform: props.transform,
            position: "fixed",
            top: "0%",
            width: "100vw",
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5rem",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <text
            // className="chainrents"
            style={{
              fontSize: window.innerWidth < 400 ? "1.6rem" : `1.8rem`,
              color: themeColor,
              marginLeft: ".5rem",
            }}
          >
            <b>{name}</b>
          </text>
          <div
            style={{
              height: "2.5rem",
              // background: "red",
              position: "absolute",
              right: windowDimensions.width <= 500 ? "4%" : "1%",
              padding: ".5rem",
            }}
          >
            <ThemeProvider theme={theme}>
              <Button
                variant="outlined"
                onClick={() => {
                  setAnim3(false);
                  setTimeout(() => {
                    setAnim2(false);
                    setTimeout(() => {
                      setAnim(false);
                    }, 300);
                  }, 300);
                  setTimeout(() => {
                    navigate("/login");
                  }, 1200);
                }}
                style={{ marginRight: ".5rem" }}
              >
                login
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAnim3(false);
                  setTimeout(() => {
                    setAnim2(false);
                    setTimeout(() => {
                      setAnim(false);
                    }, 200);
                  }, 200);
                  setTimeout(() => {
                    navigate("/signup");
                  }, 800);
                }}
              >
                Sign up
              </Button>
            </ThemeProvider>
          </div>
        </animated.div>
        <animated.div
          className="description"
          style={{ opacity: props2.opacity }}
        >
          <h1 style={{ marginLeft: "2%", color: themeColor }}>
            Rental transactions for assets
          </h1>
          {/* <h2 style={{ marginLeft: "2%" }}>
            Earn money every month on vehicle fleet rental
          </h2> */}
        </animated.div>

        <div className="icon-buttons">
          <animated.div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              opacity: props2.opacity,
              scale: props2.scale,
            }}
          >
            {/* <IconButton style={{ display: "flex", flexDirection: "column" }}> */}
            <DirectionsCar sx={{ fontSize: 100, color: themeColor }} />
            {/* </IconButton> */}
            <text style={{ color: themeColor }}>
              <b>Rental cars</b>
            </text>
          </animated.div>
          <animated.div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              opacity: props2.opacity,
              scale: props2.scale.to({ range: [0, 0.7, 1], output: [0, 0, 1] }),
            }}
          >
            {/* <IconButton style={{ display: "flex", flexDirection: "column" }}> */}
            <Apartment sx={{ fontSize: 100, color: themeColor }} />
            {/* </IconButton> */}
            <text style={{ color: themeColor }}>
              <b>Apartments</b>
            </text>
          </animated.div>
        </div>
        <animated.div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            opacity: props3.opacity,
            scale: props3.scale,
          }}
        >
          <div
            style={{
              border: `.2rem solid ${themeColor}`,
              borderRadius: ".5rem",
              display: "flex",
              // background: "red",
              width: windowDimensions.width <= 500 ? "45vw" : "25vw",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: windowDimensions.width <= 500 ? "1rem" : "1.5rem",
                margin: "1%",
                color: themeColor,
              }}
            >
              Confirm Amenities
            </Typography>
            <Hotel sx={{ fontSize: 50, color: themeColor }} />
          </div>
          <div
            style={{
              border: `.2rem solid ${themeColor}`,
              borderRadius: ".5rem",
              display: "flex",
              // background: "red",
              width: windowDimensions.width <= 500 ? "45vw" : "25vw",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              height: "20vh",
              marginLeft: "2%",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: windowDimensions.width <= 500 ? "1rem" : "1.5rem",
                margin: "1%",
                color: themeColor,
              }}
            >
              Record Key Handover
            </Typography>
            <Key sx={{ fontSize: 50, color: themeColor }} />
          </div>
        </animated.div>
        <animated.div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            margin: "2% 0% 2% 0%",
            opacity: props3.opacity,
            scale: props3.scale,
          }}
        >
          <div
            style={{
              border: `.2rem solid ${themeColor}`,
              borderRadius: ".5rem",
              display: "flex",
              // background: "red",
              width: windowDimensions.width <= 500 ? "45vw" : "25vw",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: windowDimensions.width <= 500 ? "1rem" : "1.5rem",
                margin: "1%",
                color: themeColor,
              }}
            >
              Record Wi-Fi sharing
            </Typography>
            <WifiPassword sx={{ fontSize: 50, color: themeColor }} />
          </div>
          <div
            style={{
              border: `.2rem solid ${themeColor}`,
              borderRadius: ".5rem",
              display: "flex",
              // background: "red",
              width: windowDimensions.width <= 500 ? "45vw" : "25vw",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              height: "20vh",
              marginLeft: "2%",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: windowDimensions.width <= 500 ? "1rem" : "1.5rem",
                margin: "1%",
                color: themeColor,
              }}
            >
              Share Lockbox code
            </Typography>
            <Lock sx={{ fontSize: 50, color: themeColor }} />
          </div>
        </animated.div>
        <animated.div
          className="working"
          style={{
            opacity: props3.opacity,
            scale: props3.scale,
          }}
        >
          <Card
            sx={{
              background:
                "linear-gradient(to left bottom,hsl(240, 100%, 27%,0.5),hsl(0, 0%, 0%,0.5))",
              padding: "3%",
              margin: "2%",
              borderRadius: ".5rem",
              zIndex: 1,
              backdropFilter: "blur(.3rem)",
            }}
          >
            <h1 style={{ color: themeColor }}>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: themeColor,
                  },
                }}
              >
                How it works
              </Divider>
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: themeColor, textAlign: "center" }}>
                Each rental is confirmed with owner and renter
              </h2>
              <DateRange sx={{ fontSize: 100, color: themeColor }} />
              <h2 style={{ color: themeColor, textAlign: "center" }}>
                Amenities confirmed, codes shared between owner and renter
              </h2>
              <h2 style={{ color: themeColor, textAlign: "center" }}>
                RentlinksGraph shows number of days asset has been rented
              </h2>
              <BarChart sx={{ fontSize: 100, color: themeColor }} />
            </div>
          </Card>
        </animated.div>
      </div>
      <animated.div
        style={{
          // position: "absolute",
          // bottom: "-20%",
          // background: "red",
          opacity: props3.opacity,
          marginBottom: "5%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a href="/terms" style={{ color: themeColor, marginRight: "2%" }}>
          Terms of Service
        </a>
        <a href="/privacy" style={{ color: themeColor, marginRight: "2%" }}>
          Privacy policy
        </a>
      </animated.div>
    </>
  );
}
