import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack, Snackbar, Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";

function Copyright(props) {
  return (
    <Typography variant="body2" color={themeColor} align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        Chainrents
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState(false);
  const [anim, setAnim] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const navigate = useNavigate();

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    fetch(`${url}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.get("email"),
        password: data.get("password"),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (!data.msg) {
          setSnack(true);
          setSeverity("error");
          setSnackMsg("User not found");
          return;
        } else if (data.msg === "invalid") {
          setSnack(true);
          setSeverity("error");
          setSnackMsg("Incorrect password");
          return;
        }
        localStorage.setItem("Chainrents_id", `${data.check._id}`);
        setAnim(false);
        setTimeout(() => {
          navigate({
            pathname: "/dashboard",
            search: `?id=${data.check._id}`,
          });
        }, 800);
      });
  };

  useEffect(() => {
    setAnim(true);
  }, []);

  return (
    <animated.div
      style={{
        minHeight: "100vh",
        opacity: props.opacity,
        scale: props.scale,
      }}
    >
      <ThemeProvider theme={theme}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              setSnack(false);
            }}
          >
            <Alert
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSnack(false);
              }}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {snackMsg}
            </Alert>
          </Snackbar>
        </Stack>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: themeColor }}>
              <LockOutlinedIcon color="neutral" />
            </Avatar>
            <Typography component="h1" variant="h5" color="primary">
              LOGIN
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ input: { color: themeColor } }}
                InputLabelProps={{
                  style: { color: themeColor },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ input: { color: themeColor } }}
                InputLabelProps={{
                  style: { color: themeColor },
                }}
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              {!loading ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "5%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              <Grid container>
                <Grid item xs>
                  <Link href="forgotPassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                <Link href="renterKeyHandover" variant="body2">
                  Key handover (Renters without an account)
                </Link>
              </div>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </animated.div>
  );
}
