import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import {
  Button,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getWindowDimensions from "../../custom/windowDimensions";
import { useSpring, animated, easings } from "react-spring";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function ConfirmAmenities() {
  const [owner, setOwner] = useState("");
  const [renter, setRenter] = useState("");
  const [assetName, setAssetName] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [amenitiesNA, setAmenitiesNA] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [rented, setRented] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [anim, setAnim] = useState(false);
  const [notes, setNotes] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const addAmenitiesNA = (e) => {
    if (!e.target.checked) {
      setAmenitiesNA((p) => [...p, e.target.value]);
      return;
    }
    setAmenitiesNA(() =>
      amenitiesNA.filter((amenity) => amenity !== e.target.value)
    );
  };

  const confirmBtn = () => {
    setLoading(true);
    fetch(`${url}/renterConfirm`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
        amenitiesNA,
      }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setLoading(false);
        if (data === "success") {
          setConfirm(true);
          return;
        }
        setConfirm(true);
        setRented(true);
      });
  };

  const getAmenities = () => {
    fetch(`${url}/getAmenities`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
      }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setLoadData(false);
        setAnim(true);
        if (data.date) {
          setConfirm(true);
          setOwner(data.owner);
          setRenter(data.renter);
          setAmenities(data.amenities);
          setAssetName(data.assetName);
          setNotes(data.notes);
          return;
        }
        setOwner(data.owner);
        setRenter(data.renter);
        setAmenities(data.amenities);
        setAssetName(data.assetName);
        setNotes(data.notes);
      })
      .catch(() => {
        navigate("/");
      });
  };

  useEffect(() => {
    if (!params.get("id")) {
      navigate("/");
    }
    getAmenities();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {!loadData ? (
        <animated.div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            scale: props.scale,
            opacity: props.opacity,
          }}
        >
          <Paper
            elevation={7}
            style={{
              background:
                "linear-gradient(to left bottom,hsl(240, 100%, 27%,0.5),hsl(0, 0%, 0%,0.5))",
              padding: "2%",
              width: windowDimensions.width <= 1200 ? "90vw" : "60vw",
              WebkitBackdropFilter: "blur(1rem)",
              borderRadius: ".5rem",
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                margin: "1%",
                color: themeColor,
              }}
            >
              Key/Code Handover
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "1%",
                fontSize: "1.2rem",
                color: themeColor,
                textAlign: "center",
              }}
            >
              Please confirm key handover and amenities from owner by clicking
              button below
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "1%",
                fontSize: "1.1rem",
                color: themeColor,
              }}
            >
              Owner : {owner}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "1%",
                fontSize: "1.1rem",
                color: themeColor,
              }}
            >
              Renter : {renter}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "1%",
                fontSize: "1.1rem",
                color: themeColor,
              }}
            >
              Asset name : {assetName}
            </Typography>
            {!confirm ? (
              <>
                <Typography
                  style={{
                    fontWeight: "bold",
                    margin: "1%",
                    fontSize: "1.3rem",
                    color: themeColor,
                  }}
                >
                  Confirm Amenities
                </Typography>
                <FormGroup style={{ margin: "1%" }}>
                  {amenities.length !== 0 ? (
                    amenities.map((amenity, key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            defaultChecked
                            color="primary"
                            style={{ color: themeColor }}
                          />
                        }
                        label={
                          <Typography color="primary">{amenity}</Typography>
                        }
                        value={amenity}
                        onChange={(e) => {
                          addAmenitiesNA(e);
                        }}
                      />
                    ))
                  ) : (
                    <Typography
                      style={{
                        fontSize: "1.3rem",
                        color: themeColor,
                      }}
                    >
                      No amenities to confirm
                    </Typography>
                  )}
                </FormGroup>
                <Typography
                  style={{
                    fontWeight: "bold",
                    margin: "1%",
                    fontSize: "1.3rem",
                    display: amenitiesNA.length === 0 ? "none" : "flex",
                    color: themeColor,
                  }}
                >
                  Amenities not available
                </Typography>

                {amenitiesNA.map((a, key) => (
                  <div style={{ margin: "1%", color: themeColor }} key={key}>
                    {a}
                  </div>
                ))}

                {!loading ? (
                  <Button
                    onClick={confirmBtn}
                    style={{
                      background: themeColor,
                      color: "black",
                      margin: "1%",
                    }}
                  >
                    Confirm
                  </Button>
                ) : (
                  <CircularProgress style={{ margin: "1%" }} />
                )}
                {notes ? (
                  <>
                    <Typography
                      style={{
                        fontSize: "1.5rem",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        margin: "1%",
                        color: themeColor,
                      }}
                    >
                      Additional info
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "1.3rem",
                        fontFamily: "sans-serif",
                        margin: "1%",
                        color: themeColor,
                        textAlign: "center",
                      }}
                    >
                      {notes}
                    </Typography>
                  </>
                ) : null}
              </>
            ) : (
              <Typography
                style={{
                  fontWeight: "bold",
                  margin: "1%",
                  fontSize: "1.3rem",
                  color: themeColor,
                }}
              >
                {rented
                  ? "Asset already rented"
                  : "Thank you for your confirmation"}
              </Typography>
            )}
          </Paper>
        </animated.div>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </ThemeProvider>
  );
}
