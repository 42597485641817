import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack, Snackbar, Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";

function Copyright(props) {
  return (
    <Typography variant="body2" color={themeColor} align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        Chainrents
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function ForgotPassword() {
  const [snack, setSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [anim, setAnim] = useState(false);
  const navigate = useNavigate();

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const params = new URLSearchParams(window.location.search);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    if (
      data.get("password") === data.get("confirmPassword") &&
      data.get("password")
    ) {
      setLoading(true);
      fetch(`${url}/changePassword`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: params.get("id"),
          password: data.get("password"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) {
            setSnack(true);
            setLoading(false);
            setSeverity("success");
            setSnackMsg("Password changed");
            setTimeout(() => {
              navigate("/login");
            }, 1000);
            return;
          }
          setSnack(true);
          setLoading(false);
          setSeverity("error");
          setSnackMsg("Password validation failed.");
        });
      return;
    }
    setSnack(true);
    setSeverity("error");
    setSnackMsg("Passwords do not match!");
  };

  useEffect(() => {
    setAnim(true);
  }, []);

  const sendEmail = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    fetch(`${url}/forgotPassword`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.get("email"),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.msg) {
          setLoading(false);
          setSnack(true);
          setSeverity("error");
          setSnackMsg("User not found");
          return;
        } else if (data.msg === "failed") {
          setLoading(false);
          setSnack(true);
          setSeverity("error");
          setSnackMsg("Sorry something went wrong try again.");
          return;
        }
        setLoading(false);
        setSnack(true);
        setSeverity("success");
        setSnackMsg("Email sent. Change password using the link provided");
      });
    return;
  };

  return (
    <animated.div
      style={{ height: "100vh", scale: props.scale, opacity: props.opacity }}
    >
      <ThemeProvider theme={theme}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              setSnack(false);
            }}
          >
            <Alert
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSnack(false);
              }}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {snackMsg}
            </Alert>
          </Snackbar>
        </Stack>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {params.get("id") ? (
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: themeColor }}>
                <LockOutlinedIcon color="neutral" />
              </Avatar>
              <Typography component="h1" variant="h5" color="primary">
                Change Password
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="Password"
                  autoFocus
                  sx={{ input: { color: themeColor } }}
                  InputLabelProps={{
                    style: { color: themeColor },
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="current-password"
                  sx={{ input: { color: themeColor } }}
                  InputLabelProps={{
                    style: { color: themeColor },
                  }}
                />
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                {!loading ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update password
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor: "red",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "20%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: themeColor }}>
                <LockOutlinedIcon color="neutral" />
              </Avatar>
              <Typography component="h1" variant="h5" color="primary">
                Change Password
              </Typography>
              <Box
                component="form"
                onSubmit={sendEmail}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  // type="password"
                  autoComplete="Password"
                  autoFocus
                  sx={{ input: { color: themeColor } }}
                  InputLabelProps={{
                    style: { color: themeColor },
                  }}
                />
                {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                {!loading ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send link
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor: "red",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "20%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </Box>
          )}
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </animated.div>
  );
}
