import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { url } from "../../custom/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function RecordHandOver({
  recordHandOver,
  setRecordHandOver,
  assetId,
  assetName,
  type,
}) {
  const [open, setOpen] = useState(true);
  const [owner, setOwner] = useState("");
  const [renter, setRenter] = useState("");
  const [renterEmail, setRenterEmail] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [amenity, setAmenity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [codeView, setCodeView] = useState(false);
  const [amenityView, setAmenityView] = useState(false);
  const [shareKeyView, setShareKeyView] = useState(false);
  const [shareKey, setShareKey] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const items = ["Share Key", "Add Amenities", "Custom Code"];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCredentials(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clearCode = () => {
    setCodeView(false);
    setCode("");
  };

  useEffect(() => {
    credentials.includes("Custom Code") ? setCodeView(true) : clearCode();
    if (credentials.includes("Add Amenities")) {
      setAmenityView(true);
    } else {
      setAmenities([]);
      setAmenityView(false);
    }
    if (credentials.includes("Share Key")) {
      setShareKeyView(true);
      setShareKey(true);
    } else {
      setShareKeyView(false);
      setShareKey(false);
    }
  }, [credentials]);

  const getOwner = () => {
    fetch(`${url}/getOwner`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: params.get("id") }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setOwner(`${data.firstName} ${data.lastName}`);
      });
  };

  const addAmenity = () => {
    if (amenity !== "" && amenity.trim().length !== 0) {
      setAmenities((p) => [...p, amenity]);
      setAmenity("");
      return;
    }
  };

  const handleClose = (event, reason) => {
    setOpenSnack(false);
  };

  const send = () => {
    if (renter && renterEmail && (amenities.length || code || shareKey)) {
      setLoading(true);
      fetch(`${url}/sendEmail`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          renterEmail,
          owner,
          renter,
          amenities,
          notes: code || null,
          assetId,
          assetName,
          shareKey: shareKey,
        }),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data === "success") {
            setTimeout(() => {
              setRecordHandOver(false);
            }, 1000);
            setLoading(false);
            setOpenSnack(true);
            setOpen(false);
            setAlertText("Email send successfully");
            setSeverity("success");
            return;
          } else if (data === "rented") {
            setTimeout(() => {
              setRecordHandOver(false);
            }, 1000);
            setLoading(false);
            setOpenSnack(true);
            setOpen(false);
            setAlertText("Asset already rented");
            setSeverity("error");
            return;
          }
          setOpenSnack(true);
          setAlertText("Failed to send Email");
          setSeverity("error");
        });
      return;
    }
    setOpen(false);
    setOpenSnack(true);
    setAlertText("You must fill all the inputs.");
    setSeverity("warning");
    setTimeout(() => {
      setRecordHandOver(false);
    }, 1000);
  };

  useEffect(() => {
    if (recordHandOver) {
      setOpen(true);
      return;
    }
  }, [recordHandOver]);

  useEffect(() => {
    getOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setRecordHandOver(false);
          }, 200);
        }}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle style={{ color: "black", fontWeight: "bold" }}>
          Key/Code Handover
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black", fontWeight: "bold" }}>
            Enter the following information given below
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={renter}
            id="name"
            label="Renter"
            fullWidth
            variant="standard"
            onChange={(e) => setRenter(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            value={renterEmail}
            id="name"
            label="Renter email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setRenterEmail(e.target.value);
            }}
          />
          <DialogContentText
            style={{ color: "black", fontWeight: "bold", marginTop: "2%" }}
          >
            Choose Options
          </DialogContentText>
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={credentials}
                onChange={handleChange}
                input={<OutlinedInput label="Select" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {items.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={credentials.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {shareKeyView ? (
            <>
              <DialogContentText
                style={{ color: "black", fontWeight: "bold", marginTop: "4%" }}
              >
                Share Key
              </DialogContentText>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={shareKey}
                  onChange={() => setShareKey((p) => !p)}
                  defaultChecked
                />
                <Typography>share</Typography>
              </div>
            </>
          ) : null}
          {amenityView ? (
            <>
              <DialogContentText
                style={{ color: "black", fontWeight: "bold", marginTop: "2%" }}
              >
                Add Amenities
              </DialogContentText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {amenities.map((a) => (
                  <ul style={{ margin: "1%" }}>
                    <li>{a}</li>
                  </ul>
                ))}
              </div>
              <TextField
                autoFocus
                margin="dense"
                value={amenity}
                id="name"
                label=" eg: microwave"
                fullWidth
                variant="standard"
                onKeyPress={(e) => e.key === "Enter" && addAmenity()}
                onChange={(e) => {
                  // if (e.target.value !== "" && e.target.value.trim().length !== 0) {
                  setAmenity(e.target.value);
                  // }
                }}
              />
              <Button onClick={addAmenity}>ADD</Button>
            </>
          ) : null}

          {codeView ? (
            <>
              <DialogContentText
                style={{ color: "black", fontWeight: "bold", marginTop: "2%" }}
              >
                Code
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                value={code}
                id="name"
                label="Add custom code"
                fullWidth
                variant="standard"
                multiline
                rows={4}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setRecordHandOver(false);
              }, 200);
            }}
          >
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={send}>send</Button>
          ) : (
            <CircularProgress
              style={{ margin: "1%", marginLeft: "5%", marginRight: "5%" }}
              size={20}
            />
          )}
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
}
