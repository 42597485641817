import React, { useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  CssBaseline,
  Typography,
  Link,
  Box,
  createTheme,
  Container,
  ThemeProvider,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";

function Copyright(props) {
  return (
    <Typography variant="body2" color={themeColor} align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        Chainrents
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function RenterKeyHandover() {
  const [snack, setSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [open, setOpen] = useState(false);
  const [owners, setOwners] = useState([]);
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState();
  const [owner, setOwner] = useState("");
  const [renter, setRenter] = useState("");
  const [anim, setAnim] = useState(false);

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const handleChange = (e) => {
    setOwner(e.target.value.userEmail);
  };

  const handleAsset = (e) => {
    setAsset(e.target.value);
    setOwner("");
  };

  const sendEmail = (event) => {
    setOwners([]);
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    setRenter(data.get("email"));
    fetch(`${url}/renterEmailCheck`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: data.get("email"),
      }),
    })
      .then((res) => res.json())
      .then(({ msg }) => {
        if (!msg) {
          setLoading(false);
          setSnack(true);
          setSeverity("error");
          setSnackMsg("Renter not found");
          return;
        } else if (msg === "failed") {
          setLoading(false);
          setSnack(true);
          setSeverity("error");
          setSnackMsg("Sorry something went wrong try again.");
          return;
        }
        setLoading(false);
        setSnack(true);
        setSeverity("success");
        setSnackMsg(`Renter verified`);
        setAssets(msg);
        // setAsset(msg[0]);
        msg.forEach((asset, index) => {
          fetch(`${url}/getUsers`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              assetId: asset.assetId,
            }),
          })
            .then((res) => res.json())
            .then(({ data }) => {
              setOwners((p) => [...p, data]);
            });
          if (index === msg.length - 1) {
            setOpen(true);
          }
        });
      });
    return;
  };

  const send = () => {
    setLoading2(true);
    if (asset) {
      fetch(`${url}/renterKeyHandover`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          owner,
          renter,
          assetId: asset.assetId,
        }),
      })
        .then((res) => res.json())
        .then(({ msg }) => {
          setLoading2(false);
          setOwner("");
          if (msg === "success") {
            setSnack(true);
            setSeverity("success");
            setSnackMsg(`Successfully confirmed key handover.`);
            setOpen(false);
            return;
          } else if (msg === "msg failed") {
            setSnack(true);
            setSeverity("error");
            setSnackMsg(`Something went wrong. Please try again.`);
            setOpen(false);
            return;
          }
          setSnack(true);
          setSeverity("error");
          setSnackMsg(`You have not yet confirmed key/code handover.`);
        });
      return;
    }
    setLoading2(false);
    setSnack(true);
    setSeverity("warning");
    setSnackMsg(`Choose an asset.`);
  };

  useEffect(() => {
    setAnim(true);
  }, []);

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            setTimeout(() => {}, 200);
          }}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle style={{ color: "black", fontWeight: "bold" }}>
            Key handover
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "black", fontWeight: "bold" }}>
              Enter the following information given below
            </DialogContentText>
            <FormControl fullWidth sx={{ marginTop: "5%" }}>
              <InputLabel id="demo-simple-select-label">
                Choose Asset
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={asset}
                label="Choose Asset"
                onChange={handleAsset}
              >
                {assets.map((item) => (
                  <MenuItem value={item} key={item._id}>
                    {item.assetName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              value={owner}
              id="name"
              label="Owner email"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setOwner(e.target.value);
              }}
            />
            <DialogContentText
              style={{
                color: "black",
                fontWeight: "bold",
                marginTop: "2%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Or
            </DialogContentText>
            <FormControl fullWidth sx={{ marginTop: "5%" }}>
              <InputLabel id="demo-simple-select-label">
                Choose owner
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={owner.user}
                label="Choose owner"
                onChange={handleChange}
              >
                {asset
                  ? owners.map((items) =>
                      items.map((item) =>
                        item.confirmReq && item.assetId === asset.assetId ? (
                          <MenuItem value={item} key={item._id}>
                            {item.user}
                          </MenuItem>
                        ) : null
                      )
                    )
                  : null}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setOwner("");
                setAsset();
              }}
            >
              Cancel
            </Button>
            {!loading2 ? (
              <Button onClick={send}>confirm</Button>
            ) : (
              <CircularProgress
                style={{ margin: "1%", marginLeft: "5%", marginRight: "5%" }}
                size={20}
              />
            )}
          </DialogActions>
        </Dialog>
      </div>
      <animated.div
        style={{ height: "100vh", scale: props.scale, opacity: props.opacity }}
      >
        <ThemeProvider theme={theme}>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={snack}
              autoHideDuration={6000}
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }

                setSnack(false);
              }}
            >
              <Alert
                onClose={(event, reason) => {
                  if (reason === "clickaway") {
                    return;
                  }
                  setSnack(false);
                }}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {snackMsg}
              </Alert>
            </Snackbar>
          </Stack>
          <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: themeColor }}>
                <LockOutlinedIcon color="neutral" />
              </Avatar>
              <Typography component="h1" variant="h5" color="primary">
                Enter email address
              </Typography>
              <Box
                component="form"
                onSubmit={sendEmail}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  // type="password"
                  autoComplete="Password"
                  autoFocus
                  sx={{ input: { color: themeColor } }}
                  InputLabelProps={{
                    style: { color: themeColor },
                  }}
                />

                {!loading ? (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    confirm
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      // backgroundColor: "red",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "20%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </animated.div>
    </>
  );
}
