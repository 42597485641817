import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { url } from "../../custom/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DialogBox({
  addUsers,
  setAddUsers,
  assetName,
  assetId,
  ownerId,
}) {
  const [open, setOpen] = useState(true);
  // const [owner, setOwner] = useState("");
  const [user, setUser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    setOpenSnack(false);
  };

  const send = () => {
    if (user && userEmail) {
      setLoading(true);
      fetch(`${url}/addUser`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userEmail, user, assetName, assetId, ownerId }),
      })
        .then((res) => res.json())
        .then(({ data }) => {
          if (data === "success") {
            setTimeout(() => {
              setAddUsers(false);
            }, 1000);
            setLoading(false);
            setOpenSnack(true);
            setOpen(false);
            setAlertText("Email send successfully");
            setSeverity("success");
            return;
          }
          setOpenSnack(true);
          setAlertText("Failed to send Email");
          setSeverity("error");
        });
      return;
    }
    setOpenSnack(true);
    setAlertText("You must fill all the inputs.");
    setSeverity("warning");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setAddUsers(false);
          }, 200);
        }}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle style={{ color: "black", fontWeight: "bold" }}>
          Add user to the circle
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black", fontWeight: "bold" }}>
            Enter the following information given below
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            value={owner}
            id="name"
            label="Owner"
            fullWidth
            variant="standard"
            onChange={(e) => setOwner(e.target.value)}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            value={user}
            id="name"
            label="User"
            fullWidth
            variant="standard"
            inputProps={{ maxLength: 20 }}
            onChange={(e) => setUser(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            value={userEmail}
            id="name"
            label="User email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                setAddUsers(false);
              }, 200);
            }}
          >
            Cancel
          </Button>
          {!loading ? (
            <Button onClick={send}>send</Button>
          ) : (
            <CircularProgress
              style={{ margin: "1%", marginLeft: "5%", marginRight: "5%" }}
              size={20}
            />
          )}
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
}
