import "./App.css";
import React from "react";
import HomePage from "./elements/home/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./elements/home/Login";
import Signup from "./elements/home/Signup";
import Dashboard from "./elements/dashboard/Dashboard";
import ConfirmAmenities from "./elements/confirmations/ConfirmAmenities";
import ConfirmRequest from "./elements/confirmations/ConfirmRequest";
import ForgotPassword from "./elements/home/ForgotPassword";
import RenterKeyHandover from "./elements/home/RenterKeyHandover";
import OwnerConfirm from "./elements/confirmations/OwnerConfirm";
import RenterKeyReturn from "./elements/confirmations/RenterKeyReturn";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage name={"CHAINRENTS"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/confirmAmenities" element={<ConfirmAmenities />} />
          <Route path="/confirmRequest" element={<ConfirmRequest />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/renterKeyHandover" element={<RenterKeyHandover />} />
          <Route path="/renterKeyReturn" element={<RenterKeyReturn />} />
          <Route path="/ownerConfirm" element={<OwnerConfirm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
