import React, { useState, useEffect } from "react";
import DataChart from "./DataChart";
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import RecordHandOver from "./RecordHandOver";
import DialogBox from "./DialogBox";
import { url } from "../../custom/constants";
import getWindowDimensions from "../../custom/windowDimensions";
import { useSpring, animated, easings } from "react-spring";
import ShareWifi from "./ShareWifi";
// import { isMobile } from "react-device-detect";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Assets({
  assetName,
  status,
  blkrc,
  vin_address,
  getAssets,
  assetId,
  ownerId,
  type,
}) {
  const [open, setOpen] = useState(false);
  const [recordHandOver, setRecordHandOver] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [shareWifi, setShareWifi] = useState(false);
  const [users, setUsers] = useState([]);
  const [userTable, setUserTable] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertText, setAlertText] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [animX, setAnimX] = useState(false);

  const { anim } = useSpring({
    from: { anim: 0 },
    anim: animX ? 1 : 0,
    // reset: true,
    config: { duration: 500 },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deleteAsset = () => {
    fetch(`${url}/deleteAsset`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assetId }),
    }).then(() => {
      handleClose();
      getAssets();
    });
  };

  const User = ({ userName, confirmReq, width, animation, comment }) => {
    const [anim1, setAnim1] = useState(false);

    const props = useSpring({
      scale: anim1 ? 1 : 0,
      config: { duration: 800, easing: easings.easeInOutQuint },
    });

    useEffect(() => {
      animation && setAnim1(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <>
        <animated.div
          style={{
            // backgroundColor:'red',
            overflowWrap: "anywhere",
            width: windowDimensions.width <= 950 ? "20vw" : "8vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            scale: animation ? props.scale : 1,
            // margin:'3%'
          }}
        >
          <Person
            sx={{
              fontSize:
                windowDimensions.width <= 950
                  ? `${windowDimensions.width / 200}rem`
                  : "3rem",
              color:
                typeof confirmReq === "undefined"
                  ? "orange"
                  : confirmReq === true
                  ? "mediumseagreen"
                  : "crimson",
            }}
          />
          <text
            style={{
              fontSize:
                windowDimensions.width <= 950
                  ? `${windowDimensions.width / 650}rem`
                  : "1rem",
            }}
          >
            {userName}
          </text>
          {typeof confirmReq !== "undefined" ? (
            <>
              {confirmReq === true ? (
                <text
                  style={{
                    color: "mediumseagreen",
                    fontSize:
                      windowDimensions.width <= 950
                        ? `${windowDimensions.width / 650}rem`
                        : "1rem",
                  }}
                >
                  Accepted
                </text>
              ) : (
                <text
                  style={{
                    color: "crimson",
                    fontSize:
                      windowDimensions.width <= 950
                        ? `${windowDimensions.width / 650}rem`
                        : "1rem",
                  }}
                >
                  denied
                </text>
              )}
            </>
          ) : (
            <text
              style={{
                color: "orange",
                fontSize:
                  windowDimensions.width <= 950
                    ? `${windowDimensions.width / 650}rem`
                    : "1rem",
              }}
            >
              pending...
            </text>
          )}
        </animated.div>
      </>
    );
  };

  const UserRow = ({ row }) => {
    return (
      <>
        {users.map((user, index) =>
          index >= row * 3 - 3 && index < row * 3 ? (
            <div key={index}>{user}</div>
          ) : null
        )}
      </>
    );
  };

  const getUsers = (comment) => {
    fetch(`${url}/getUsers`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assetId }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        data.forEach((user) => {
          if (data.indexOf(user) === 0) {
            setUsers([
              <User
                userName={user.user}
                confirmReq={user.confirmReq}
                width={windowDimensions.width}
                comment={comment}
                animation={
                  comment === "first" || comment === "add" ? true : false
                }
              />,
            ]);
            return;
          }
          setUsers((p) => [
            ...p,
            <User
              userName={user.user}
              confirmReq={user.confirmReq}
              width={windowDimensions.width}
              comment={comment}
              animation={
                comment === "first" || comment === "add" ? true : false
              }
            />,
          ]);
        });
        setLoading(false);
      });
  };

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  // useEffect(() => {
  //   if (!firstLoad && !isMobile) {
  //     getUsers("resize");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [windowDimensions]);

  useEffect(() => {
    const rowSize = Math.ceil(users.length / 3);
    for (let i = 0; i < rowSize; i++) {
      if (i === 0) {
        setUserTable([<UserRow row={i + 1} />]);
        continue;
      }
      setUserTable((p) => [...p, <UserRow row={i + 1} />]);
    }
  }, [users]);

  useEffect(() => {
    if (!addUsers && !firstLoad) {
      setTimeout(() => {
        getUsers("add");
        setTimeout(() => {
          getUsers("resize");
        }, 1000);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUsers]);

  useEffect(() => {
    setFirstLoad(false);
    setLoading(true);
    getUsers("first");
    setTimeout(() => {
      getUsers("resize");
    }, 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert
          onClose={() => setOpenSnack(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      {recordHandOver ? (
        <RecordHandOver
          recordHandOver={recordHandOver}
          setRecordHandOver={setRecordHandOver}
          assetId={assetId}
          assetName={assetName}
          type={type}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <text
          style={{
            fontSize:
              windowDimensions.width <= 950
                ? `${windowDimensions.width / 500}rem`
                : "1.5rem",
            marginRight: "3%",
            marginLeft: "3%",
            color: "midnightblue",
          }}
        >
          Asset name : {assetName}
        </text>
        <text
          style={{
            fontSize:
              windowDimensions.width <= 950
                ? `${windowDimensions.width / 500}rem`
                : "1.5rem",
            marginLeft: "4%",
            color: "midnightblue",
          }}
        >
          {type === "Car" ? "VIN" : "Address"} : {vin_address}
        </text>
        <text
          style={{
            fontSize:
              windowDimensions.width <= 950
                ? `${windowDimensions.width / 500}rem`
                : "1.5rem",
            marginRight: "3%",
            marginLeft: "3%",
            color: "midnightblue",
          }}
        >
          Status :{" "}
          <text
            style={{
              color: status === "Rented" ? "crimson" : "mediumseagreen",
            }}
          >
            {status}
          </text>
        </text>
        {/* <text
          style={{
            fontSize:
              windowDimensions.width <= 950
                ? `${windowDimensions.width / 500}rem`
                : "1.5rem",
            marginLeft: "3%",
          }}
        >
          Blockchain Record : {blkrc}
        </text> */}
        {/* <IconButton
          style={{ color: "black", marginLeft: "2%" }}
          onClick={() => {}}
        >
          <Edit sx={{ fontSize: "2rem", color: "limegreen" }} />
        </IconButton> */}
      </div>
      <div
        style={{
          // backgroundColor: "red",
          // width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: windowDimensions.width <= 950 ? "column" : "row",
        }}
      >
        <div
          style={{
            // backgroundColor: "red",
            width: "60vw",
            marginTop: windowDimensions.width <= 950 ? "5%" : "0%",
            // marginLeft: "2%",
          }}
        >
          <DataChart />
        </div>
        <div
          style={{
            // backgroundColor: "red",
            padding: "5%",
            width: "80vw",
            display: "flex",
            alignItems: "center",
            justifyContent:
              users.length === 1 ? "space-around" : "space-between",
            marginTop: windowDimensions.width <= 950 ? "5%" : "0%",
            flexDirection: windowDimensions.width <= 950 ? "column" : "row",
          }}
        >
          <Stack
            spacing={2}
            direction={windowDimensions.width <= 950 ? "row" : "column"}
          >
            {type !== "Car" ? (
              <Button
                variant="contained"
                size={windowDimensions.width <= 950 ? "small" : "large"}
                style={{
                  color: "black",
                  fontSize: windowDimensions.width <= 950 ? ".7rem" : ".9rem",
                  backgroundColor: "orange",
                }}
                onClick={() => {
                  setShareWifi(true);
                }}
              >
                Share Wifi
              </Button>
            ) : null}
            <Button
              variant="contained"
              size={windowDimensions.width <= 950 ? "small" : "large"}
              style={{
                color: "black",
                fontSize: windowDimensions.width <= 950 ? ".7rem" : ".9rem",
                backgroundColor: "lightblue",
              }}
              onClick={() => {
                setAddUsers(true);
              }}
            >
              + Share
            </Button>
            {status !== "Rented" ? (
              <Button
                // disabled={status !== "Rented" ? true : false}
                onClick={() =>
                  status === "Rented"
                    ? setRecordHandOver(true)
                    : setRecordHandOver(true)
                }
                size={windowDimensions.width <= 950 ? "small" : "large"}
                variant="contained"
                style={{
                  color: "black",
                  fontSize: windowDimensions.width <= 950 ? ".7rem" : ".9rem",
                }}
                sx={{
                  backgroundColor: "CornflowerBlue",
                  "&:hover": {
                    // backgroundColor: status === "Rented" ? "#b9c1c4" : "default",
                    // cursor: status === "Rented" ? "not-allowed" : "-moz-grab",
                  },
                }}
              >
                + Record Handover
              </Button>
            ) : (
              <Button
                onClick={() => {
                  fetch(`${url}/renterKeyReturn`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id: assetId, val: "resend" }),
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      console.log(data);
                      if (data.data === "success") {
                        setOpenSnack(true);
                        setSeverity("success");
                        setAlertText("Email send successfully");
                        return;
                      }
                      setOpenSnack(true);
                      setSeverity("error");
                      setAlertText("Failed to send email");
                    });
                }}
                size={windowDimensions.width <= 950 ? "small" : "large"}
                variant="contained"
                style={{
                  color: "black",
                  fontSize: windowDimensions.width <= 950 ? ".7rem" : ".9rem",
                }}
                sx={{
                  backgroundColor: "cornflowerblue",
                }}
              >
                Resend rental confirmation
              </Button>
            )}
          </Stack>
          <div
            style={
              {
                // backgroundColor: "red",
              }
            }
          >
            <div
              style={{
                // backgroundColor: "red",

                display: "flex",
                flexDirection: "row",
                marginTop: windowDimensions.width <= 950 ? "5%" : "0%",
                justifyContent: "center",
              }}
            >
              {!loading ? (
                <>
                  {!showUsers ? (
                    <div
                      style={{
                        // backgroundColor: "red",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: " center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {users.map((user, index) =>
                          users.indexOf(user) <= 2 ? (
                            <div key={index}>{user} </div>
                          ) : null
                        )}
                      </div>
                      {users.length > 3 ? (
                        <Button
                          style={{ marginTop: "4%" }}
                          variant="text"
                          onClick={() => {
                            setShowUsers(true);
                            setAnimX(true);
                          }}
                        >
                          and {users.length - 3}{" "}
                          {users.length - 3 === 1 ? "other" : "others"}.
                        </Button>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <animated.div
                        style={{
                          height: anim.interpolate({
                            range: [0, 1],
                            output: [
                              windowDimensions.width <= 450 ? "7vh" : "10vh",
                              windowDimensions.width <= 450 ? "20vh" : "32vh",
                            ],
                          }),
                          display: "flex",
                          flexDirection: "column",
                          overflowY: "scroll",
                        }}
                      >
                        {userTable.map((user, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            {user}
                          </div>
                        ))}
                      </animated.div>
                      <Button
                        style={{ marginTop: "4%" }}
                        variant="text"
                        onClick={() => {
                          setAnimX(false);
                          setTimeout(() => {
                            setShowUsers(false);
                          }, 500);
                        }}
                      >
                        Collapse
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <CircularProgress style={{ marginTop: "10%" }} />
              )}
            </div>
          </div>
          {addUsers ? (
            <DialogBox
              addUsers={addUsers}
              setAddUsers={setAddUsers}
              assetName={assetName}
              assetId={assetId}
              ownerId={ownerId}
            />
          ) : null}
          {shareWifi ? <ShareWifi setShareWifi={setShareWifi} /> : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size={windowDimensions.width <= 950 ? "small" : "large"}
          onClick={handleClickOpen}
          style={{
            color: "black",
            fontSize: ".9rem",
            backgroundColor: "crimson",
            // margin:'2%'
          }}
        >
          Delete Asset
        </Button>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Asset"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this asset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          <Button onClick={deleteAsset}>delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
