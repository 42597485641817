import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";

function Copyright(props) {
  return (
    <Typography variant="body2" color={themeColor} align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        Chainrents
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function SignUp() {
  const [visibility, setVisibility] = useState(false);
  const [errorValue, setErrorValue] = useState(false);
  const [anim, setAnim] = useState(false);
  const [snack, setSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  let navigate = useNavigate();

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    if (
      data.get("email") !== "" &&
      data.get("password") !== "" &&
      data.get("firstName") !== "" &&
      data.get("lastName") !== ""
    ) {
      fetch(`${url}/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.get("email"),
          password: data.get("password"),
          firstName: data.get("firstName"),
          lastName: data.get("lastName"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.msg === "invalid") {
            setSnack(true);
            setErrorValue(true);
            setSeverity("error");
            setSnackMsg(
              "Check email address(if valid) or password (must contain atleast 1 capital letter ,number and a special character)"
            );
          } else if (data.msg === "exists") {
            setSnack(true);
            setErrorValue(true);
            setSeverity("error");
            setSnackMsg("User already exists");
          } else if (data.msg === "user created") {
            setSnack(true);
            setErrorValue(false);
            setSeverity("success");
            setSnackMsg("User created");
            setTimeout(() => {
              navigate("/login");
            }, 1000);
          }
        });
      return;
    }
    setErrorValue(true);
    setSnack(true);
    setSeverity("error");
    setSnackMsg("Cant leave blank spaces");
  };

  useEffect(() => {
    setAnim(true);
  }, []);

  return (
    <animated.div
      style={{ minHeight: "100vh", scale: props.scale, opacity: props.opacity }}
    >
      <ThemeProvider theme={theme}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={6000}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              setSnack(false);
            }}
          >
            <Alert
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }
                setSnack(false);
              }}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {snackMsg}
            </Alert>
          </Snackbar>
        </Stack>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: themeColor }}>
              <LockOutlinedIcon color="neutral" />
            </Avatar>
            <Typography component="h1" variant="h5" color="primary">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errorValue ? true : false}
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    sx={{ input: { color: themeColor } }}
                    InputLabelProps={{
                      style: { color: themeColor },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errorValue ? true : false}
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    sx={{ input: { color: themeColor } }}
                    autoComplete="family-name"
                    InputLabelProps={{
                      style: { color: themeColor },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errorValue ? true : false}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    sx={{ input: { color: themeColor } }}
                    autoComplete="email"
                    InputLabelProps={{
                      style: { color: themeColor },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    error={errorValue ? true : false}
                    sx={{ m: 0, width: "100%", input: { color: themeColor } }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      style={{ color: themeColor }}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      name="password"
                      id="password"
                      autoComplete="new-password"
                      type={visibility ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              visibility
                                ? setVisibility(false)
                                : setVisibility(true)
                            }
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visibility ? (
                              <VisibilityOff color="primary" />
                            ) : (
                              <Visibility color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {/* {!visibility ? (
                  <IconButton
                    style={{
                      display: "flex",
                      position: "absolute",
                      bottom: "49%",
                      right: "32%",
                      flexDirection: "column",
                      color: "black",
                    }}
                    onClick={() => setVisibility(true)}
                  >
                    <Visibility sx={{ fontSize: 30, color: "black" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{
                      display: "flex",
                      position: "absolute",
                      bottom: "49%",
                      right: "32%",
                      flexDirection: "column",
                      color: "black",
                    }}
                    onClick={() => setVisibility(false)}
                  >
                    <VisibilityOff sx={{ fontSize: 30, color: "black" }} />
                  </IconButton>
                )} */}
                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    </animated.div>
  );
}
