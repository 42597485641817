import React, { useState, forwardRef, useEffect, useRef } from "react";
import "../../App.css";
import Assets from "./Assets";
import "../../index.css";
import {
  Button,
  Box,
  Alert,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Select,
  Slide,
  FormControl,
  MenuItem,
  CircularProgress,
  Paper,
  Snackbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";
import getWindowDimensions from "../../custom/windowDimensions";
import { browserName, isMobile } from "react-device-detect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SentimentDissatisfied } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function Dashboard() {
  const ref = useRef(null);

  const [height, setHeight] = useState(0);
  const [assets, setAssets] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [vin_address, setVin_address] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  // const [searchParams, setSearchParams] = useSearchParams();
  const [openSnack, setOpenSnack] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [severity, setSeverity] = useState("success");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const [flip, setFlip] = useState(false);
  const [animY, setAnimY] = useState(false);
  const [anim1, setAnim1] = useState(false);
  const [anim2, setAnim2] = useState(false);
  const [anim3, setAnim3] = useState(false);

  const { x } = useSpring({
    from: { x: 0 },
    x: flip ? 1 : 0,
    config: { duration: 800 },
  });

  const { anim } = useSpring({
    from: { anim: 0 },
    anim: animY ? 1 : 0,
    config: { duration: 800 },
  });

  const props1 = useSpring({
    scale: anim1 ? 1 : 0,
    opacity: anim1 ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });
  const props2 = useSpring({
    scale: anim2 ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });
  const props3 = useSpring({
    scale: anim3 ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const addAsset = () => {
    console.log(type);
    if (name && vin_address && type) {
      fetch(`${url}/addAsset`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id,
          name,
          vin_address,
          type,
        }),
      }).then(() => {
        getAssets();
      });
      return;
    }
    setOpenSnack(true);
    setSeverity("warning");
    setAlertText("You must fill all inputs");
  };

  useEffect(() => {
    console.log(type);
  }, [type]);

  const getAssets = () => {
    fetch(`${url}/getAssets`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
      }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setLoading(false);

        data.forEach((asset) => {
          if (data.indexOf(asset) === 0) {
            setAssets([
              <Assets
                assetName={asset.assetName}
                status={asset.status}
                blkrc={asset.blockchainRecord}
                vin_address={asset.vin_address}
                getAssets={getAssets}
                assetId={asset._id}
                type={asset.type}
                ownerId={params.get("id")}
              />,
            ]);
            return;
          }
          setAssets((p) => [
            ...p,
            <Assets
              assetName={asset.assetName}
              status={asset.status}
              blkrc={asset.blockchainRecord}
              vin_address={asset.vin_address}
              getAssets={getAssets}
              assetId={asset._id}
              type={asset.type}
              ownerId={params.get("id")}
            />,
          ]);
        });
        setFlip(true);
        setTimeout(() => {
          setAnimY(true);
        }, 400);
        setTimeout(() => {
          setTimeout(() => {
            setAnim1(true);
          }, Math.floor(Math.random() * 800));
          setTimeout(() => {
            setAnim2(true);
          }, Math.floor(Math.random() * 800));
          setTimeout(() => {
            setAnim3(true);
          }, Math.floor(Math.random() * 800));
        }, 400);
        if (data.length === 0) {
          setAssets([]);
          return;
        }
      });
  };

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
    if (ref.current.offsetHeight) setHeight(ref.current.offsetHeight);
  };

  useEffect(() => {
    setHeight(ref.current.offsetHeight);
  }, [assets]);

  useEffect(() => {
    if (!localStorage.getItem("Chainrents_id")) {
      navigate("/");
      return;
    }
    if (!params.get("id")) {
      navigate("/");
    }
    setId(params.get("id"));
    setLoading(true);
    getAssets();
    setTimeout(() => {
      setHeight(ref.current.offsetHeight);
    }, 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <animated.div
        style={{
          background: "linear-gradient(to right top,cadetblue,lavenderblush)",
          opacity: props1.opacity,
          position: "absolute",
          width: "100vw",
          height,
          zIndex: 1,
        }}
      />
      <div
        ref={ref}
        style={{
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 2,
        }}
      >
        <animated.div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            opacity: anim.to({ range: [0, 1], output: [0, 1] }),
          }}
        >
          <text
            style={{
              fontSize: windowDimensions.width <= 950 ? "1rem" : "2.5rem",
              color: "transparent",
              margin: "2%",
              fontFamily: "gillsans",
            }}
          >
            Extra div
          </text>
          <text
            style={{
              fontSize: windowDimensions.width <= 950 ? "2rem" : "2.5rem",
              color: "black",
              margin: "2%",
              fontFamily: "gillsans",
              zIndex: 2,
            }}
          >
            My Assets
          </text>
          <Button
            variant="contained"
            size={windowDimensions.width <= 950 ? "small" : "large"}
            onClick={() => {
              setFlip(false);
              setTimeout(() => {
                setAnimY(false);
              }, 400);
              setTimeout(() => {
                setTimeout(() => {
                  setAnim1(false);
                }, Math.floor(Math.random() * 800));
                setTimeout(() => {
                  setAnim2(false);
                }, Math.floor(Math.random() * 800));
                setTimeout(() => {
                  setAnim3(false);
                }, Math.floor(Math.random() * 800));
              }, 400);
              setTimeout(() => {
                localStorage.removeItem("Chainrents_id");
                navigate("/");
              }, 1600);
            }}
            style={{
              margin: "3%",
              backgroundColor: "maroon",
              zIndex: 2,
            }}
          >
            Logout
          </Button>
        </animated.div>
        {loading ? (
          <ThemeProvider theme={theme}>
            <CircularProgress style={{ margin: "10%" }} />
          </ThemeProvider>
        ) : (
          <div style={{ zIndex: 10 }}>
            {assets.length === 0 ? (
              <animated.div
                style={{
                  height: "60vh",
                  // background: "red",
                  zIndex: 2,
                  opacity: props1.opacity,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <text
                  style={{
                    color: "gray",
                    margin: "5%",
                    textAlign: "center",
                    fontSize: 50,
                  }}
                >
                  You don't have any assets
                </text>
                <SentimentDissatisfied sx={{ fontSize: 100, color: "gray" }} />
              </animated.div>
            ) : (
              assets.map((asset, index) => (
                <animated.div
                  key={index}
                  style={{
                    // backgroundColor:'black',
                    opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
                    // scale: x.interpolate({
                    //   range: [0, 0.8, 1],
                    //   output: [0, 1.1, 1],
                    // }),
                    translateX: x.to({
                      range: [0, 0.6 + (0.4 / assets.length) * index, 1],
                      output: ["-200vw", "10vw", "0vw"],
                    }),
                  }}
                >
                  <Box
                    sx={{
                      // backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "90%",
                      marginBottom: "2%",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                      },
                    }}
                  >
                    {browserName === "Chrome" && !isMobile ? (
                      <Paper
                        elevation={7}
                        style={{
                          // background: "hsl(240, 67%, 94%,0.7)",
                          background:
                            "linear-gradient(to right bottom,hsl(240, 67%, 94%,0.5),hsl(340, 100%, 97%,0.5))",
                          padding: "2%",
                          width:
                            windowDimensions.width <= 1200 ? "90vw" : "70vw",
                          backdropFilter: "blur(1rem)",
                          height: "90%",
                          borderRadius: ".5rem",
                          zIndex: 2,
                        }}
                      >
                        {asset}
                      </Paper>
                    ) : (
                      <Paper
                        elevation={7}
                        style={{
                          // background: "hsl(240, 67%, 94%,0.7)",
                          background:
                            "linear-gradient(to right bottom,hsl(240, 67%, 94%,0.5),hsl(340, 100%, 97%,0.5))",
                          padding: "2%",
                          width:
                            windowDimensions.width <= 1200 ? "90vw" : "70vw",
                          WebkitBackdropFilter: "blur(1rem)",
                          height: "90%",
                          borderRadius: ".5rem",
                          zIndex: 2,
                        }}
                      >
                        {asset}
                      </Paper>
                    )}
                  </Box>
                </animated.div>
              ))
            )}
          </div>
        )}
        {/* <Assets model="toyota prius 2012" status="available" blkrc="Gghkv$56" /> */}
        <animated.div
          style={{
            width: windowDimensions.width <= 950 ? "50vh" : "50vw",
            height: windowDimensions.width <= 950 ? "50vh" : "50vw",
            // background: "hsl(16, 100%, 66%,.8)",
            background: "linear-gradient(to right top,crimson,aliceblue)",
            opacity: anim.to({ range: [0, 1], output: [0, 1] }),
            borderRadius: windowDimensions.width <= 950 ? "50vh" : "50vw",
            position: "fixed",
            top: windowDimensions.width <= 750 ? "-15%" : "-35%",
            left: windowDimensions.width <= 750 ? "-45%" : "-10%",
            scale: props1.scale,
            zIndex: 1,
          }}
        />
        <animated.div
          style={{
            width: windowDimensions.width <= 950 ? "10vh" : "5vw",
            height: windowDimensions.width <= 950 ? "10vh" : "5vw",
            // background: "hsl(16, 100%, 66%,.8)",
            background: "linear-gradient(to left bottom,purple,oldlace)",
            opacity: anim.to({ range: [0, 1], output: [0, 1] }),
            borderRadius: windowDimensions.width <= 950 ? "50vh" : "50vw",
            position: "fixed",
            top: windowDimensions.width <= 750 ? "3%" : "14%",
            right: windowDimensions.width <= 750 ? "-5%" : "11%",
            scale: props2.scale,
            zIndex: 1,
          }}
        />
        <animated.div
          style={{
            width: windowDimensions.width <= 950 ? "30vh" : "30vw",
            height: windowDimensions.width <= 950 ? "30vh" : "30vw",
            // background: "hsl(16, 100%, 66%,.8)",
            background: "linear-gradient(to right top,darkblue,beige)",
            opacity: anim.to({ range: [0, 1], output: [0, 1] }),
            borderRadius: windowDimensions.width <= 950 ? "50vh" : "50vw",
            position: "fixed",
            bottom: windowDimensions.width <= 750 ? "-15%" : "-30%",
            right: windowDimensions.width <= 750 ? "-25%" : "-5%",
            scale: props3.scale,
            zIndex: 1,
          }}
        />
        <animated.div
          style={{ margin: "3%", zIndex: 2, opacity: props1.opacity }}
        >
          <Button
            variant="contained"
            size={windowDimensions.width <= 950 ? "small" : "large"}
            onClick={() => setOpen(true)}
          >
            Add assets
          </Button>
        </animated.div>
        {/* <Button
          variant="contained"
          style={{ display: "flex", position: "absolute", top: "3%" }}
          onClick={() => setAnimY(!animY)}
        >
          Add
        </Button> */}
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle>Asset</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the details for assets</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              value={name}
              id="name"
              label="Name"
              fullWidth
              variant="standard"
              onChange={(e) => setName(e.target.value)}
            />
            <FormControl fullWidth sx={{ marginTop: "7%" }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                id="demo-simple-select"
                value={type}
                label="Type"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={1}>Car</MenuItem>
                <MenuItem value={2}>Apartment</MenuItem>
                <MenuItem value={3}>Office space</MenuItem>
                <MenuItem value={4}>House</MenuItem>
                <MenuItem value={5}>Equipment</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              value={vin_address}
              id="vin"
              label={
                type === 1 ? "VIN" : type === 2 ? "Address" : "VIN/Address"
              }
              fullWidth
              variant="standard"
              onChange={(e) => setVin_address(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setType("");
                setName("");
                setVin_address("");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                addAsset();
                setOpen(false);
                setType("");
                setName("");
                setVin_address("");
              }}
            >
              ADD
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={() => setOpenSnack(false)}
        >
          <Alert
            onClose={() => setOpenSnack(false)}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
