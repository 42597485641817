import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  ButtonGroup,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { url, themeColor } from "../../custom/constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getWindowDimensions from "../../custom/windowDimensions";
import { useSpring, animated, easings } from "react-spring";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function ConfirmAmenities() {
  const [owner, setOwner] = useState("");
  const [assetName, setAssetName] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [anim, setAnim] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const sendReply = (val) => {
    fetch(`${url}/sendReply`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
        reply: val === "confirm" ? true : false,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data === "success") {
          setLoading(false);
          setConfirm(true);
        }
      });
  };

  const getUserCircle = () => {
    fetch(`${url}/getUserCircle`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
      }),
    })
      .then((res) => res.json())
      .then(({ data }) => {
        setAnim(true);
        setFetching(true);
        setOwner(data.owner);
        setAssetName(data.assetName);
        if (data.date) {
          setConfirm(true);
          return;
        }
      })
      .catch(() => {
        navigate("/");
      });
  };

  useEffect(() => {
    if (!params.get("id")) {
      navigate("/");
    }
    getUserCircle();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <animated.div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          scale: props.scale,
          opacity: props.opacity,
        }}
      >
        {fetching ? (
          <Paper
            elevation={7}
            style={{
              background:
                "linear-gradient(to left bottom,hsl(240, 100%, 27%,0.5),hsl(0, 0%, 0%,0.5))",
              padding: "2%",
              width: windowDimensions.width <= 1200 ? "90vw" : "60vw",
              WebkitBackdropFilter: "blur(1rem)",
              borderRadius: ".5rem",
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              // variant="string"
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                margin: "1%",
                color: themeColor,
                textAlign: "center",
              }}
            >
              Confirm Request
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "1%",
                fontSize: "1.2rem",
                color: themeColor,
                textAlign: "center",
              }}
            >
              {`${owner}`} has added you to the Chainrents circle '
              {`${assetName}`}'. Please confirm or deny the request
            </Typography>
            {confirm ? (
              <Typography
                style={{
                  fontWeight: "bold",
                  margin: "1%",
                  fontSize: "1.1rem",
                  color: themeColor,
                  textAlign: "center",
                }}
              >
                Thank you for your reply.
              </Typography>
            ) : (
              <>
                {!loading ? (
                  <ButtonGroup
                    style={{ marginLeft: "1%" }}
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      style={{
                        backgroundColor: "mediumseagreen",
                        color: themeColor,
                      }}
                      onClick={() => {
                        setLoading(true);
                        sendReply("confirm");
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      style={{ backgroundColor: "crimson", color: themeColor }}
                      onClick={() => {
                        setLoading(true);
                        sendReply("deny");
                      }}
                    >
                      Deny
                    </Button>
                  </ButtonGroup>
                ) : (
                  <CircularProgress />
                )}
              </>
            )}
            <div
              style={{
                margin: "0%",
                padding: "0%",
                marginTop: "2%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  margin: "1%",
                  fontSize: "1.1rem",
                  color: themeColor,
                  textAlign: "center",
                }}
              >
                Requestor information
              </Typography>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: owner.length < 6 ? 100 : owner.length * 12.5,
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Person sx={{ fontSize: 80, color: themeColor }} />
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      color: themeColor,
                      textAlign: "center",
                    }}
                  >
                    {`${owner}`}
                  </Typography>
                </div>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    color: themeColor,
                    textAlign: "center",
                  }}
                >
                  Requestor
                </Typography>
              </div>
            </div>
          </Paper>
        ) : (
          <div
            style={{
              display: "flex",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </animated.div>
    </ThemeProvider>
  );
}
