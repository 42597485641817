import React, { useEffect, useState } from "react";
import {
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { url, themeColor } from "../../custom/constants";
import { useSpring, animated, easings } from "react-spring";
import getWindowDimensions from "../../custom/windowDimensions";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: themeColor,
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

export default function RenterKeyHandover() {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [assetName, setAssetName] = useState("");
  const [owner, setOwner] = useState("");
  const [anim, setAnim] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const props = useSpring({
    scale: anim ? 1 : 0,
    opacity: anim ? 1 : 0,
    config: { duration: 800, easing: easings.easeInOutQuint },
  });

  const getValues = () => {
    fetch(`${url}/renterKeyReturn`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
        val: "get",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAnim(true);
        if (data.msg === "closed") {
          return setConfirm(true);
        }
        setFetching(false);
        setAssetName(data.asset.assetName);
        setOwner(`${data.owner.firstName} ${data.owner.lastName}`);
      });
  };
  const confirmKeyReturn = () => {
    fetch(`${url}/renterKeyReturn`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: params.get("id"),
        val: "post",
      }),
    })
      .then((res) => res.json())
      .then(({ msg }) => {
        setLoading(false);
        if (msg === "success") {
          setConfirm(true);
        }
      });
  };

  useEffect(() => {
    if (!params.get("id")) {
      navigate("/");
    }
    getValues();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <animated.div
        style={{
          height: "100vh",
          scale: props.scale,
          opacity: props.opacity,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <ThemeProvider theme={theme}>
          <Paper
            elevation={7}
            style={{
              background:
                "linear-gradient(to left bottom,hsl(240, 100%, 27%,0.5),hsl(0, 0%, 0%,0.5))",
              padding: "2%",
              width: windowDimensions.width <= 1200 ? "80vw" : "30vw",
              WebkitBackdropFilter: "blur(1rem)",
              borderRadius: ".5rem",
              zIndex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <>
              <Typography
                // variant="string"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  margin: "1%",
                  color: themeColor,
                  textAlign: "center",
                }}
              >
                Return Key
              </Typography>
              <Typography
                style={{
                  fontWeight: "bold",
                  margin: "1%",
                  fontSize: "1.2rem",
                  color: themeColor,
                  textAlign: "center",
                  padding: windowDimensions.width <= 950 ? "10% 0%" : "0%",
                }}
              >
                Return key back to owner
                <br />
                <br />
              </Typography>
              {!fetching ? (
                <>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      margin: "1%",
                      fontSize: "1.2rem",
                      color: themeColor,
                      textAlign: "center",
                      padding: windowDimensions.width <= 950 ? "10% 0%" : "0%",
                    }}
                  >
                    Owner : {`${owner}`}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      margin: "1%",
                      fontSize: "1.2rem",
                      color: themeColor,
                      textAlign: "center",
                      padding: windowDimensions.width <= 950 ? "10% 0%" : "0%",
                    }}
                  >
                    Asset : {`${assetName}`}
                  </Typography>
                </>
              ) : null}
              {confirm ? (
                <Typography
                  style={{
                    color: themeColor,
                    fontWeight: "bold",
                    margin: "1%",
                    fontSize: "1.1rem",
                    textAlign: "center",
                  }}
                >
                  Return request send.
                </Typography>
              ) : (
                <>
                  {!loading ? (
                    <Button
                      style={{
                        backgroundColor: "mediumseagreen",
                        color: themeColor,
                        marginTop: "2%",
                      }}
                      onClick={() => {
                        setLoading(true);
                        confirmKeyReturn();
                      }}
                    >
                      Return
                    </Button>
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </>
              )}
            </>
          </Paper>
        </ThemeProvider>
      </animated.div>
    </>
  );
}
