import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    yAxes: {
      grid: {
        drawBorder: true,
        // color: "#FFFFFF",
      },
      ticks: {
        beginAtZero: true,
        color: "midnightblue",
        fontSize: 12,
      },
    },
    xAxes: {
      grid: {
        drawBorder: true,
        // color: "#FFFFFF",
      },
      ticks: {
        beginAtZero: true,
        color: "midnightblue",
        fontSize: 12,
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "midnightblue",
      },
    },
    title: {
      display: true,
      text: "Bar Chart",
      color: "midnightblue",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [250, 78, 135, 40, 92, 30, 45],
      // backgroundColor: "rgba(255, 99, 132, 0.5)",
      backgroundColor: "darkslategrey",
    },
    {
      label: "Dataset 2",
      data: [120, 50, 40, 78, 10, 300, 95],
      // backgroundColor: "rgba(53, 162, 235, 0.5)",
      backgroundColor: "ghostwhite",
    },
  ],
};

export default function DataChart() {
  return <Bar options={options} data={data} />;
}
